import './subheading.css';
const Subheading = ({ title, id, additionalClass, style }) => {
  return (
    <div
      id={id}
      className={`subheading-container ${additionalClass}`}
      style={style}
    >
      {title}
    </div>
  );
};

export default Subheading;
