export const projectList = [
  {
    title: 'Annotate.io',
    description:
      'An education & productivity web platform that creates dynamic study sheets to increase active learning based on lectures or videos.',
    github: 'https://github.com/RishanR/Annotate.io',
    image: 'annotate',
    tech: [
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'HTML', icon: 'html5' },
      { name: 'CSS', icon: 'css3' },
      { name: 'React', icon: 'react' },
      { name: 'Python', icon: 'python' },
      { name: 'Flask', icon: 'flask' },
    ],
    ribbon: {
      link: 'https://devpost.com/software/annotate-ai-m1hsjq',
      text: 'WINNER',
    },
  },
  {
    title: 'NoteSense',
    description:
      'A notes accessibility mobile application that aids users who have hearing and vision impairments to create PDF notes based on images or audio in real time using optical character recognition (OCR) and speech recognition.',
    github: 'https://github.com/RishanR/NotesSense',
    image: 'notesense',
    tech: [
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'React Native', icon: 'react' },
      { name: 'Python', icon: 'python' },
      { name: 'Flask', icon: 'flask' },
      { name: 'Google Cloud', icon: 'googlecloud' },
    ],
    ribbon: { link: 'https://devpost.com/software/notesense', text: 'WINNER' },
  },
  {
    title: 'Diabetes Doctor',
    description:
      'Diabetes Doctor is a virtual platform built to help individuals screen for diabetes using an accurate machine learning model and enable users to track and manage their medical data.',
    github: 'https://github.com/RishanR/DiabetesPredict',
    demo: 'http://diabetesdoctor.herokuapp.com/',
    image: 'diabetes-doctor',
    tech: [
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'HTML', icon: 'html5' },
      { name: 'CSS', icon: 'css3' },
      { name: 'React', icon: 'react' },
      { name: 'Python', icon: 'python' },
      { name: 'Flask', icon: 'flask' },
      { name: 'SQLAlchemy', icon: 'sqlalchemy' },
    ],
  },
  {
    title: 'Chatroom',
    description:
      'A real-time online chatting application that allows people around the world to chat together in virtual rooms through text and emojis.',
    github: 'https://github.com/RishanR/Chatroom',
    demo: 'https://chatroom-messenger.netlify.app/',
    image: 'chatroom',
    tech: [
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'HTML', icon: 'html5' },
      { name: 'CSS', icon: 'css3' },
      { name: 'React', icon: 'react' },
      { name: 'Node.js', icon: 'nodejs' },
      { name: 'Socket.IO', icon: 'socketio' },
    ],
  },
  {
    title: 'HTTP Web Server',
    description:
      'An HTTP Web Server that hosts static HTML files and delivers them to end users on the Local Area Network through TCP/IP socket connections. Developed from scratch in C++.',
    github: 'https://github.com/RishanR/HttpWebServer',
    image: 'http-web-server',
    tech: [{ name: 'C++', icon: 'cplusplus' }],
  },
  {
    title: 'Vocal Analyzer',
    description:
      'A vocal analyzing web application that records user vocals and compares it to the original vocals of the song being sung to provide an analysis on the pitch difference between the user’s vocals and the original vocals.',
    github: 'https://github.com/RishanR/VocalAnalyzer',
    image: 'voice-analyzer',
    tech: [
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'HTML', icon: 'html5' },
      { name: 'CSS', icon: 'css3' },
      { name: 'React', icon: 'react' },
      { name: 'Python', icon: 'python' },
      { name: 'Django', icon: 'django' },
    ],
  },
  {
    title: 'Repudium',
    description:
      'A third person role-playing adventure/action game created in the Unity 3D Game Engine in which the medieval main protagonist battles knights and monsters on a journey to conquer the land.',
    github:
      'https://github.com/RishanR/Repudium---Third-Person-Role-Playing-Game',
    image: 'repudium',
    tech: [
      { name: 'C#', icon: 'csharp' },
      { name: 'Unity 3D', path: '/icons/unity3d-skill.png' },
    ],
  },
  {
    title: 'Stocker',
    description:
      'A social stock management app that allows users to showcase their virtual stock portfolio to followers and get recent tailored news on the stock market based on their portfolio.',
    github: 'https://github.com/RishanR/Stocker',
    image: 'stocker',
    tech: [
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'React Native', icon: 'react' },
      { name: 'Python', icon: 'python' },
      { name: 'Flask', icon: 'flask' },
      { name: 'Firebase', icon: 'firebase' },
      { name: 'SQLAlchemy', icon: 'sqlalchemy' },
    ],
  },
  {
    title: 'LED Globe Display',
    description:
      'A semi-circular strip of 32 LEDs that spins at a rapid speed, creating the illusion of a spherical display. Programmed the Arduino microcontroller using C.',
    image: 'globe-display',
    tech: [
      { name: 'C', icon: 'c' },
      { name: 'Arduino', path: '/icons/arduino-skill.svg' },
    ],
  },
  {
    title: 'Contacts',
    description:
      'A full stack contact keeping web application where users can manage personal contacts saved in the cloud.',
    github: 'https://github.com/RishanR/Contacts',
    demo: 'https://contacts-storage.herokuapp.com/',
    image: 'contacts',
    tech: [
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'HTML', icon: 'html5' },
      { name: 'CSS', icon: 'css3' },
      { name: 'React', icon: 'react' },
      { name: 'Node.js', icon: 'nodejs' },
      { name: 'Express.js', icon: 'express' },
      { name: 'MongoDB', icon: 'mongodb' },
    ],
  },
  {
    title: 'LaunchED',
    description:
      'A mobile application that enhances high school student experience by bringing school announcements, clubs management, internal communication, and resource consolidation to their fingertips.',
    github: 'https://github.com/RishanR/LaunchED',
    image: 'launched',
    tech: [
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'HTML', icon: 'html5' },
      { name: 'CSS', icon: 'css3' },
      { name: 'React Native', icon: 'react' },
      { name: 'Firebase', icon: 'firebase' },
    ],
  },
  {
    title: 'My Health Pal',
    image: 'coming-soon',
    tech: [
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'HTML', icon: 'html5' },
      { name: 'CSS', icon: 'css3' },
      { name: 'React Native', icon: 'react' },
      { name: 'Python', icon: 'python' },
      { name: 'Flask', icon: 'flask' },
      { name: 'Firebase', icon: 'firebase' },
      { name: 'Cassandra', path: '/icons/cassandra-skill.svg' },
    ],
  },
];

export default projectList;
