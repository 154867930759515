export const socialList = [
  {
    label: 'Email',
    url: 'mailto:rishanratna@hotmail.com',
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/rishanratna/',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/rishanratnasivam/',
  },
  {
    label: 'Github',
    url: 'https://github.com/RishanR',
  },
];
