import { ReactComponent as LandingPageCard } from '../../images/LandingPageNameAlone.svg';
import { ReactComponent as LandingPageWho } from '../../images/LandingPageWHOAMI.svg';
// import MatrixParallax from 'react-matrix-parallax';
import Tilt from 'react-parallax-tilt';
import React, { useState, useContext } from 'react';
import { MainContext } from '../../context/MainContext';
import './mainlanding.css';

const MainLanding = (props) => {
  const { refs } = props;
  const [overlay, setOverlay] = useState('landing-page-card-out');

  const context = useContext(MainContext);

  const landingPageBorder = context.isMobile
    ? {
        onClick: () =>
          refs.aboutRef.current.scrollIntoView({ behavior: 'smooth' }),
      }
    : {
        onMouseEnter: () => setOverlay('landing-page-card-in'),
        onMouseLeave: () => setOverlay('landing-page-card-out'),
      };

  return (
    <React.Fragment>
      {/* <MatrixParallax
        color="rgba(255,255,255,0.3)"
        backgroundColor="rgba(25, 42, 86, 1)"
      ></MatrixParallax> */}
      <Tilt
        tiltReverse="true"
        trackOnWindow="true"
        tiltMaxAngleX={context.isMobile ? '0' : '15'}
        tiltMaxAngleY={context.isMobile ? '0' : '15'}
        perspective="500"
        className="landing-page-card"
      >
        <div {...landingPageBorder} className="landing-page-card-border">
          <div className="landing-page-card-actual">
            <LandingPageCard className="landing-page-card-svg" />
          </div>
          {!context.isMobile && (
            <div
              onClick={() =>
                refs.aboutRef.current.scrollIntoView({ behavior: 'smooth' })
              }
              className={overlay}
            >
              <div className="landing-page-who-container">
                <LandingPageWho className="landing-page-who-svg" />
              </div>
            </div>
          )}
        </div>
      </Tilt>
    </React.Fragment>
  );
};

export default MainLanding;
