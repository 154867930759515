import React, { useState, useContext, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Subheading from '../Subheading/subheading';
import Card from '../Card/card';
import './projects.css';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import Pagination from '../PaginationFiles/Pagination';
import projectList from '../../content/project-list';
import { MainContext } from '../../context/MainContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Projects = () => {
  const context = useContext(MainContext);

  //This number will change on mobile (probably to 1 or 2 - most likely 1)
  const [numCardsOnPage, setNumCardsOnPage] = useState(6);
  const [pageIndex, setPageIndex] = useState(0);
  const [is1500, setIs1500] = useState(false);
  const [chevronAnimate, setChevronAnimate] = useState(true);

  const numPages = Math.ceil(projectList.length / numCardsOnPage);
  let pageContent = [];
  let pages = [];

  for (let i = 0; i < numPages; i++) {
    pageContent.push([]);
    for (
      let j = numCardsOnPage * i;
      j < numCardsOnPage * i + numCardsOnPage;
      j++
    ) {
      if (projectList[j] === undefined) {
        break;
      } else {
        pageContent[i].push(
          //Add card here
          <div className="card-container-padding">
            {/* Send project information through this card */}
            <Card
              title={projectList[j].title}
              description={projectList[j].description}
              github={projectList[j].github}
              demo={projectList[j].demo}
              image={projectList[j].image}
              tech={projectList[j].tech}
              ribbon={projectList[j].ribbon}
            />
          </div>
        );
      }
    }
    pages.push(<div className="projects-page-container">{pageContent[i]}</div>);
  }

  const swipableStyles = !context.isMobile
    ? {
        display: 'grid',
      }
    : { paddingTop: 20, paddingBottom: 30, paddingLeft: 20, paddingRight: 20 };

  // const slides = projectList.map((project) => (
  //   <Card
  //     title={project.title}
  //     description={project.description}
  //     github={project.github}
  //     demo={project.demo}
  //     image={project.image}
  //     tech={project.tech}
  //   />
  // ));

  useEffect(() => {
    if (context.isMobile) {
      setNumCardsOnPage(1);
    } else if (is1500) {
      setNumCardsOnPage(4);
    } else {
      setNumCardsOnPage(6);
    }
    setPageIndex(0);
  }, [context.isMobile, is1500]);

  useEffect(() => {
    if (pageIndex === 0) {
      setChevronAnimate(true);
    } else {
      setChevronAnimate(false);
    }
  }, [pageIndex]);

  window.addEventListener('resize', () => {
    if (window.innerWidth < 1200) {
      context.setIsMobile(true);
      setIs1500(false);
    } else if (window.innerWidth < 1500 && window.innerWidth >= 1200) {
      context.setIsMobile(false);
      setIs1500(true);
    } else {
      context.setIsMobile(false);
      setIs1500(false);
    }
  });

  useEffect(() => {
    if (window.innerWidth < 1200) {
      context.setIsMobile(true);
      setIs1500(false);
    } else if (window.innerWidth < 1500 && window.innerWidth >= 1200) {
      context.setIsMobile(false);
      setIs1500(true);
    } else {
      context.setIsMobile(false);
      setIs1500(false);
    }
  }, []);

  const handleChangeIndex = (index) => {
    setPageIndex(index);
  };

  return (
    <React.Fragment>
      <div className="projects-swipable-container">
        <Subheading title="PROJECTS" />
        {/* {context.isMobile && (
          <div className="projects-carousel-container">
            <Carousel slides={slides} />
          </div>
        )} */}

        <div className="projects-swipable-views-container">
          <SwipeableViews
            style={{
              height: '100%',
              width: '100%',
              ...swipableStyles,
            }}
            index={pageIndex}
            onChangeIndex={handleChangeIndex}
            threshold={3}
            enableMouseEvents
          >
            {pages}
          </SwipeableViews>
        </div>

        {pageIndex > 0 && (
          <FontAwesomeIcon
            className="projects-go-left"
            icon={faChevronLeft}
            onClick={() => pageIndex > 0 && setPageIndex(pageIndex - 1)}
          />
        )}

        {pageIndex + 1 < numPages && (
          <FontAwesomeIcon
            className={`projects-go-right${
              chevronAnimate ? ' projects-go-animate' : ''
            }`}
            icon={faChevronRight}
            onClick={() =>
              pageIndex < numPages - 1 && setPageIndex(pageIndex + 1)
            }
          />
        )}

        {projectList.length > numCardsOnPage && (
          <Pagination
            dots={numPages}
            index={pageIndex}
            onChangeIndex={handleChangeIndex}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Projects;
