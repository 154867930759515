import React, { useRef, useContext, useState } from 'react';
import {
  faUser,
  faCode,
  faBriefcase,
  faEnvelope,
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import composeRefs from '@seznam/compose-react-refs';
import { MainContext } from '../../context/MainContext';
import './sidebar.css';
import { slide as Menu } from 'react-burger-menu';

const SideBar = (props) => {
  const { views, refs } = props;

  const context = useContext(MainContext);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: window.innerHeight * 0.014,
    },
  }))(Tooltip);

  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

  const renderItems = (
    <>
      <div className="icons">
        <div
          onClick={() => {
            refs.aboutRef.current.scrollIntoView({ behavior: 'smooth' });
            context.isMobile && setMobileSidebarOpen(false);
          }}
          className={
            views.inViewAbout && !views.inViewWork
              ? 'sidebar-icons-selected'
              : 'sidebar-icons'
          }
        >
          <FontAwesomeIcon icon={faUser} />
          {context.isMobile && (
            <span className="sidebar-icons-text">About</span>
          )}
        </div>
        <div
          onClick={() => {
            refs.workRef.current.scrollIntoView({ behavior: 'smooth' });
            context.isMobile && setMobileSidebarOpen(false);
          }}
          className={
            views.inViewWork && !views.inViewProjects
              ? 'sidebar-icons-selected'
              : 'sidebar-icons'
          }
        >
          <FontAwesomeIcon icon={faBriefcase} />
          {context.isMobile && <span className="sidebar-icons-text">Work</span>}
        </div>
        <div
          onClick={() => {
            refs.projectsRef.current.scrollIntoView({ behavior: 'smooth' });
            context.isMobile && setMobileSidebarOpen(false);
          }}
          className={
            views.inViewProjects && !views.inViewContact
              ? 'sidebar-icons-selected'
              : 'sidebar-icons'
          }
        >
          <FontAwesomeIcon icon={faCode} />
          {context.isMobile && (
            <span className="sidebar-icons-text">Projects</span>
          )}
        </div>
        <div
          onClick={() => {
            refs.contactRef.current.scrollIntoView({ behavior: 'smooth' });
            context.isMobile && setMobileSidebarOpen(false);
          }}
          className={
            views.inViewContact ? 'sidebar-icons-selected' : 'sidebar-icons'
          }
        >
          <FontAwesomeIcon icon={faEnvelope} />
          {context.isMobile && (
            <span className="sidebar-icons-text">Contact</span>
          )}
        </div>
      </div>
    </>
  );

  const toggleMenu = (state) => {
    if (!state.isOpen) {
      setMobileSidebarOpen(false);
    } else if (state.isOpen) {
      setMobileSidebarOpen(true);
    }
  };
  return (
    <>
      {!context.isMobile && (
        <div className="sidebar">
          <div
            onClick={() =>
              refs.landingRef.current.scrollIntoView({ behavior: 'smooth' })
            }
            className="sidebar-icons logo-border"
          >
            <div className="logo-content"></div>
          </div>
          {renderItems}
          <div className="sidebar-fill logo-border"></div>
        </div>
      )}
      {context.isMobile && (
        <Menu
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}
          isOpen={mobileSidebarOpen}
          onStateChange={toggleMenu}
          right
        >
          {renderItems}
        </Menu>
      )}
    </>
  );
};

export default SideBar;
