import React, { useState, useEffect, useContext } from 'react';
import Subheading from '../Subheading/subheading';
import './work.css';
import WorkCard from '../WorkCard/workcard';
import workList from '../../content/work-list';
import { MainContext } from '../../context/MainContext';

const Work = ({ views }) => {
  const context = useContext(MainContext);
  const [workHeight, setWorkHeight] = useState('100%');
  const [viewportHeight, setViewportHeight] = useState('100%');
  window.addEventListener('resize', () => {
    let workContainer = document.querySelector('.work-container');
    setWorkHeight(workContainer.clientHeight);
    setViewportHeight(window.innerHeight);
  });
  useEffect(() => {
    let workContainer = document.querySelector('.work-container');
    setWorkHeight(workContainer.clientHeight);
    setViewportHeight(window.innerHeight);
  }, []);

  return (
    <div>
      {!views.inViewProjects && !context.isMobile && (
        <Subheading additionalClass="work-header-sticky" title="WORK" />
      )}
      {views.inViewProjects && !context.isMobile && (
        <Subheading
          style={{ top: workHeight - viewportHeight }}
          additionalClass="work-header-relative"
          title="WORK"
        />
      )}
      {context.isMobile && <Subheading title="WORK" />}
      <div className="work-content-container">
        {/* <div className="work-timeline-container"></div> */}
        {workList.map((experience) => (
          <WorkCard {...experience} />
        ))}
      </div>
    </div>
  );
};

export default Work;
