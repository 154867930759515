import React from 'react';
import './icon-title.css';

const IconTitle = ({ details }) => {
  return (
    <div className="icon-title-container">
      {details.devicon && (
        <i
          className={`devicon-${details.devicon}-${
            details.devicon === 'express' || details.devicon === 'tensorflow'
              ? 'original'
              : 'plain'
          } icon-title-icon`}
        ></i>
      )}
      {details.path && (
        <img className="icon-title-icon-img" src={details.path} />
      )}
      <h5 className="icon-title-name">{details.name}</h5>
    </div>
  );
};

export default IconTitle;
