import React, { useRef, useEffect, useContext } from 'react';
import $ from 'jquery';
import logo from './logo.svg';
import SideBar from './components/SideBar/sidebar';
import { useInView } from 'react-intersection-observer';
import MainLanding from './components/MainLanding/mainlanding';
import About from './components/About/about';
import Projects from './components/Projects/projects';
import Work from './components/Work/work';
import Contact from './components/Contact/contact';
import composeRefs from '@seznam/compose-react-refs';
import { ToastContainer, toast } from 'react-toastify';
import { MainContext } from './context/MainContext';
import smoothscroll from 'smoothscroll-polyfill';

import './App.css';

const MainCode = () => {
  const context = useContext(MainContext);

  smoothscroll.polyfill();

  const [landing, inViewLanding] = useInView({
    threshold: 0.001,
  });
  const landingRef = useRef(null);

  const [about, inViewAbout] = useInView({
    threshold: 0.001,
  });
  const aboutRef = useRef(null);

  const [projects, inViewProjects] = useInView({
    threshold: 0.001,
  });
  const projectsRef = useRef(null);

  const [work, inViewWork] = useInView({
    threshold: 0.001,
  });
  const workRef = useRef(null);

  const [contact, inViewContact] = useInView({
    threshold: 0.001,
  });
  const contactRef = useRef(null);

  window.addEventListener('resize', () => {
    if (window.innerWidth < 1200) {
      context.setIsMobile(true);
    } else {
      context.setIsMobile(false);
    }
  });

  useEffect(() => {
    if (window.innerWidth < 1200) {
      context.setIsMobile(true);
    } else {
      context.setIsMobile(false);
    }
  }, []);
  return (
    <div id="outer-container" className="App">
      {!context.isMobile && (
        <div height="100vh" width="4vw">
          <SideBar
            views={{
              inViewLanding,
              inViewAbout,
              inViewProjects,
              inViewWork,
              inViewContact,
            }}
            refs={{ landingRef, aboutRef, projectsRef, workRef, contactRef }}
          />
        </div>
      )}
      {context.isMobile && (
        <SideBar
          views={{
            inViewLanding,
            inViewAbout,
            inViewProjects,
            inViewWork,
            inViewContact,
          }}
          refs={{ landingRef, aboutRef, projectsRef, workRef, contactRef }}
        />
      )}

      <div id="page-wrap" className="app-content">
        <div
          ref={composeRefs(landing, landingRef)}
          className="landing-page-card"
        >
          <MainLanding refs={{ aboutRef }} />
        </div>
        <div ref={composeRefs(about, aboutRef)} className="about-container">
          <About refs={{ contactRef }} id="about" />
        </div>
        <div
          id="workContainer"
          ref={composeRefs(work, workRef)}
          className="work-container"
        >
          <Work views={{ inViewProjects }} />
        </div>
        <div
          ref={composeRefs(projects, projectsRef)}
          className="projects-container"
        >
          <Projects />
        </div>
        <div
          ref={composeRefs(contact, contactRef)}
          className="contact-container"
        >
          <Contact />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MainCode;
