import React, { useContext, useState } from 'react';
import Tilt from 'react-parallax-tilt';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import WWW from '../../images/www-white.svg';
import { MainContext } from '../../context/MainContext';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Card = (props) => {
  const { title, description, github, demo, image, tech, ribbon } = props;

  const context = useContext(MainContext);

  const [showDescription, setShowDescription] = useState(false);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: window.innerHeight * 0.014,
    },
  }))(Tooltip);

  const renderContent = (
    <>
      <div className="card-title-container">
        <div className="card-title">{title}</div>
        <div className="card-title-drop">{title}</div>
        <div className="card-mobile-icons">
          {github && context.isMobile && (
            <a
              target="_blank"
              rel="noreferrer"
              href={github}
              className="card-learn-more-button-link"
            >
              <i className={'devicon-github-plain card-learn-more-icon'}></i>
            </a>
          )}
          {demo && context.isMobile && (
            <>
              <div className="card-learn-more-button-placeholder"></div>
              <a
                target="_blank"
                rel="noreferrer"
                href={demo}
                className="card-learn-more-button-link"
              >
                <img src={WWW} className={'card-learn-more-icon'} />
              </a>
            </>
          )}
        </div>
      </div>
      <div
        className="card-learn-more-container"
        onClick={() =>
          context.isMobile && !showDescription && setShowDescription(true)
        }
      >
        <div className="card-learn-more-container-options">
          {github && !context.isMobile && (
            <a
              target="_blank"
              rel="noreferrer"
              href={github}
              className="card-learn-more-button-link"
            >
              <div className="card-learn-more-button">
                <span>Github</span>
                <i className={'devicon-github-plain card-learn-more-icon'}></i>
              </div>
            </a>
          )}
          {demo && !context.isMobile && (
            <>
              <div className="card-learn-more-button-placeholder"></div>
              <a
                target="_blank"
                rel="noreferrer"
                href={demo}
                className="card-learn-more-button-link"
              >
                <div className="card-learn-more-button">
                  <span>Website</span>
                  <img
                    src={WWW}
                    width={20}
                    height={20}
                    className={'card-learn-more-icon'}
                  />
                </div>
              </a>
            </>
          )}
          {description && !context.isMobile && (
            <>
              <div className="card-learn-more-button-placeholder"></div>
              <div
                className="card-learn-more-button"
                onClick={() => setShowDescription(true)}
              >
                <span>Learn More</span>
              </div>
            </>
          )}
        </div>
        {description && (
          <div
            className={`card-description-container ${
              showDescription ? 'card-description-expand' : ''
            }`}
          >
            <FontAwesomeIcon
              className="card-description-close"
              icon={faTimes}
              onClick={() => setShowDescription(false)}
            />
            <div className="card-description-text">{description}</div>
          </div>
        )}
      </div>
      <div className="card-tech-stack-container">
        {tech.map((language) => {
          if (language.path) {
            return (
              <LightTooltip title={language.name}>
                <img className="card-tech-stack-icon-img" src={language.path} />
              </LightTooltip>
            );
          } else {
            return (
              <LightTooltip title={language.name}>
                <i
                  className={`devicon-${language.icon}-${
                    language.icon === 'express' ? 'original' : 'plain'
                  } card-tech-stack-icon`}
                ></i>
              </LightTooltip>
            );
          }
        })}
      </div>
    </>
  );

  return (
    <>
      {!context.isMobile && (
        <Tilt
          tiltReverse="true"
          tiltMaxAngleX="10"
          tiltMaxAngleY="10"
          scale={1.038}
          className={`card-container card-background-${image}`}
        >
          {ribbon && (
            <div className="ribbon ribbon-top-left">
              <a href={ribbon.link} target="_blank" rel="noopener noreferrer">
                {ribbon.text}
              </a>
            </div>
          )}
          {renderContent}
        </Tilt>
      )}
      {context.isMobile && (
        <div className={`card-container card-background-${image}`}>
          {ribbon && (
            <div className="ribbon ribbon-top-left">
              <a href={ribbon.link} target="_blank" rel="noopener noreferrer">
                {ribbon.text}
              </a>
            </div>
          )}
          {renderContent}
        </div>
      )}
    </>
  );
};

export default Card;
