import React, { useState, useContext } from 'react';
import Subheading from '../Subheading/subheading';
import './contact.css';
import { ToastContainer, toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import emailjs from 'emailjs-com';
import { SocialIcon } from 'react-social-icons';
import { socialList } from '../../content/social-list';
import Loading from '../../images/loading.gif';
import { MainContext } from '../../context/MainContext';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const context = useContext(MainContext);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: window.innerHeight * 0.014,
    },
  }))(Tooltip);

  const [loading, setLoading] = useState(false);

  emailjs.init(process.env.REACT_APP_EMAILJS_USER_KEY);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_KEY,
        process.env.REACT_APP_EMAILJS_TEMPLATE_KEY,
        e.target
      )
      .then(
        function (response) {
          toast.success('Successfully sent email! 🎉');
          e.target.reset();
          setLoading(false);
        },
        function (error) {
          toast.error('❗ An error occured. Please try again.');
          setLoading(false);
        }
      );
  };

  return (
    <React.Fragment>
      <Subheading title="CONTACT" />
      <div className="contact-content-container">
        <form
          id="contact-form"
          className="contact-form-container"
          onSubmit={handleSubmitForm}
        >
          <input
            className="contact-input"
            required
            type="text"
            placeholder="Name"
            name="name"
          ></input>
          <input
            className="contact-input"
            required
            type="email"
            placeholder="Email"
            name="email"
          ></input>
          <textarea
            className="contact-input"
            cols={60}
            rows={6}
            placeholder="Message"
            name="message"
            required
          />
          <button type="submit" className="contact-button" disabled={loading}>
            {loading ? (
              <img className="contact-loading" src={Loading} alt="loading..." />
            ) : (
              'Send'
            )}
          </button>
        </form>
        <div className="contact-socials">
          {socialList.map((social) => (
            <LightTooltip title={social.label}>
              <div>
                <SocialIcon
                  target="_blank"
                  rel="noopener noreferrer"
                  bgColor={'transparent'}
                  fgColor={'white'}
                  className="contact-social-icon"
                  url={social.url}
                  label={social.label}
                  style={{
                    width: context.isMobile ? 40 : window.innerHeight * 0.06,
                    height: context.isMobile ? 40 : window.innerHeight * 0.06,
                  }}
                />
              </div>
            </LightTooltip>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
