import React, { useContext } from 'react';
import Subheading from '../Subheading/subheading';
import './about.css';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import {
  languages,
  frontend,
  backend,
  tools,
  design,
} from '../../content/skills-list';
import IconTitle from '../IconTitle/icon-title';
import ReactTypingEffect from 'react-typing-effect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContext } from '../../context/MainContext';

const About = ({ id, refs }) => {
  const context = useContext(MainContext);

  const renderSkills = (
    <div className="about-skills">
      <div className="about-skills-inner-container">
        <h3 className="about-skills-subheading">Languages</h3>
        <div className="about-skills-skill-list">
          {languages
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => {
              return <IconTitle details={item} />;
            })}
        </div>
      </div>

      <div className="about-skills-inner-container">
        <h3 className="about-skills-subheading">Frontend</h3>
        <div className="about-skills-skill-list">
          {frontend
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => {
              return <IconTitle details={item} />;
            })}
        </div>
      </div>

      <div className="about-skills-inner-container">
        <h3 className="about-skills-subheading">Backend</h3>
        <div className="about-skills-skill-list">
          {backend
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => {
              return <IconTitle details={item} />;
            })}
        </div>
      </div>

      <div className="about-skills-inner-container">
        <h3 className="about-skills-subheading">Tools</h3>
        <div className="about-skills-skill-list">
          {tools
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => {
              return <IconTitle details={item} />;
            })}
        </div>
      </div>

      <div className="about-skills-inner-container">
        <h3 className="about-skills-subheading">Design</h3>
        <div className="about-skills-skill-list">
          {design
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => {
              return <IconTitle details={item} />;
            })}
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Subheading id={id} title="ABOUT" />
      <div className="about-content-container">
        <div className="about-content">
          <div className="about-description">
            <h1 className="about-description-title">
              I am a{' '}
              <span className="about-description-title-typing">
                <ReactTypingEffect
                  text={[
                    'software developer.',
                    'designer.',
                    'musician.',
                    'tech enthusiast.',
                  ]}
                  typingDelay={1000}
                  speed={100}
                  eraseDelay={3000}
                  eraseSpeed={50}
                />
              </span>
            </h1>
            <div className="about-description-text">
              <p>
                Hey 👋! My name is{' '}
                <span style={{ fontWeight: 'bold', color: '#ff6063' }}>
                  Rishan Ratnasivam
                </span>
                {', '}
                and I am a Computer Engineering student at the University of
                Waterloo with an ever-growing passion for computer hardware and
                software technology.
              </p>
              <p>
                Being a software developer, I have numerous years of experience
                designing and developing applications through work, education,
                and projects.
              </p>
              <p>
                In my spare time, I love tackling coding challenges, working
                out, and producing music.
              </p>
            </div>
            <div className="about-description-button-container">
              <button
                className="about-button-emphasized"
                onClick={() =>
                  refs.contactRef.current.scrollIntoView({ behavior: 'smooth' })
                }
              >
                Get In Touch
              </button>
            </div>
          </div>
          {!context.isMobile && renderSkills}
        </div>
        <div className="about-profile-photo-container">
          <div className="about-profile-photo"></div>
        </div>
      </div>
      {context.isMobile && renderSkills}
    </React.Fragment>
  );
};

export default About;
