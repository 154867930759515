export const workList = [
  {
    logo: 'faire',
    name: 'Faire',
    jobTitle: 'Backend Engineer',
    startDate: 'Sep 2023',
    endDate: 'Present',
    description: (
      <>
        {
          'Developing a high-impact feature to ensure buyer success and retention on our online marketplace using Uber H3, Kotlin, AWS, and SQL, projecting an astounding 2.5% gross merchandise value (GMV) lift.'
        }
      </>
    ),
  },
  {
    logo: 'faire',
    name: 'Faire',
    jobTitle: 'Android Engineer',
    startDate: 'Jan 2023',
    endDate: 'Apr 2023',
    description: (
      <>
        {
          'Designed and developed an Android live chat client using Smooch.io, Zendesk, Kotlin, and the MVVM design pattern resulting in a 60% reduction in issue resolution times and a streamlined customer experience on the marketplace'
        }
        <br />
        <br />
        {
          'Optimized product filter searches by implementing nested search and search auto-expansion, increasing filter search usage by 22%'
        }
        <br />
        <br />
        {
          'Lead and managed the end-to-end development of feature that strategically added country flags alongside product postings, effectively attracting and retaining international customers and increasing gross merchandise value'
        }
      </>
    ),
  },
  {
    logo: 'index-exchange',
    name: 'Index Exchange',
    jobTitle: 'Software Engineer',
    startDate: 'Jan 2022',
    endDate: 'Apr 2022',
    description: (
      <>
        {
          'Developed a cron job that periodically removes stale deals on the ad exchange with Go, Kubernetes, and ArgoCD, improving deal pipeline processing capacity by 40%'
        }
        <br />
        <br />
        {
          'Implemented a robust task-based asynchronous API wrapper for the cron job with a rate-limiter and timeout using Goroutines'
        }
        <br />
        <br />
        {
          'Contributed towards a massive Gitlab CI/CD modularization project, significantly reducing Index Exchange CI/CD development times'
        }
        <br />
        <br />
        {
          'Co-developed a Slack bot using Python to provide network-wide deployment summaries, simplifying engineering deployments'
        }
      </>
    ),
  },
  {
    logo: 'opal',
    name: 'Opal, llc.',
    jobTitle: 'Software Engineer (Blockchain)',
    startDate: 'Jan 2022',
    endDate: 'May 2022',
    description: (
      <>
        {
          'Added support for crypto wallets by integrating MetaMask, Coinbase, and Trust payment services into a modern NFT marketplace'
        }
        <br />
        <br />
        {
          'Migrated core blockchain system from Django to Express and optimized XRP and Ethereum endpoints, increasing speeds by 60%'
        }
        <br />
        <br />
        {
          'Built and deployed custom Ethereum smart contracts for ERC-721 token (NFT) minting and transactions using Solidity and HardHat'
        }
        <br />
        <br />
        {
          'Developed an auction service that allows users to make real-time bids on NFTs in the marketplace using MongoDB and web sockets'
        }
      </>
    ),
  },
  {
    logo: 'pepperdata',
    name: 'Pepperdata',
    jobTitle: 'Frontend Developer',
    startDate: 'May 2021',
    endDate: 'Aug 2021',
    description: (
      <>
        {
          'Designed and developed a full stack emailable reports feature using React, Java, SendGrid SMTP Service, and Amazon SES to allow users to send software performance reports to colleagues'
        }
        <br />
        <br />
        {
          'Built complete unit and integration tests using React Testing Library and Jest to automate meticulous testing of web components and greatly reduce UI bugs in the application dashboard'
        }
        <br />
        <br />
        {
          'Resolved various frontend and backend bugs tracked in the GitHub issue tracker, significantly improving product performance'
        }
      </>
    ),
  },
  {
    logo: 'futurecom',
    name: 'Futurecom Systems Group',
    jobTitle: 'Software Developer',
    startDate: 'Sep 2020',
    endDate: 'Dec 2020',
    description: (
      <>
        {
          'Developed a configuration file tester using C to perform bit-by-bit comparison between product EEPROM and a configuration file to verify successful loading of saved configuration'
        }
        <br />
        <br />
        {
          'Performed over 200 regression and feature tests on product firmware and programming software, and reported observed results in Jira and RMsis'
        }
        <br />
        <br />
        {
          'Created and revised over 100 test scripts to test features efficiently and rigorously, resulting in a 15% increase in testing speeds'
        }
      </>
    ),
  },
  {
    logo: 'futurecom',
    name: 'Futurecom Systems Group',
    jobTitle: 'Junior Developer',
    startDate: 'Jan 2020',
    endDate: 'Apr 2020',
    description: (
      <>
        {
          'Performed regression tests on the Digital Vehicular Repeater System, a radio base station used in conjunction with Motorola’s APX Mobile Radios'
        }
        <br />
        <br />
        {
          'Executed over 200 software tests to evaluate the functionality of the DVR firmware and reported observed results in Jira and Confluence'
        }
        <br />
        <br />
        {
          'Developed 4 different applications using Python and VBA to automate tasks and streamlined the product development workflow'
        }
      </>
    ),
  },
  {
    logo: 'cfli',
    name: 'Centre for Leadership and Innovation (CFLI)',
    jobTitle: 'Special Projects Lead Coordinator',
    startDate: 'Jun 2018',
    endDate: 'Aug 2019',
    description: (
      <>
        {'Coordinated a Youth Documentary Film-Making program'}
        <br />
        <br />
        {
          'Assisted CFLI’s directors in running a Youth Leadership Program promoting leadership and innovation amongst youth'
        }
        <br />
        <br />
        {'Directed, filmed, and edited a promotional video'}
      </>
    ),
  },
  {
    logo: 'satec',
    name: 'SATEC @ W A Porter Collegiate Institute',
    jobTitle: 'C++ Programming Club Facilitator',
    startDate: 'Sept 2018',
    endDate: 'Jan 2019',
    description: (
      <>
        {
          'Taught C++ Programming to more than 25 students in a programming club'
        }
      </>
    ),
  },
];

export default workList;
