import React, { useContext } from 'react';
import { MainContext } from '../../context/MainContext';
import './workcard.css';

const WorkCard = (props) => {
  const { logo, name, jobTitle, startDate, endDate, location, description } =
    props;

  const context = useContext(MainContext);

  return (
    <div className="workcard-main-container">
      {!context.isMobile && (
        <div className="workcard-timeline">
          <div className="workcard-date">
            {startDate} <br />- <br />
            {endDate}
          </div>
        </div>
      )}
      <div className="workcard-outer-container">
        <div className="workcard-inner-container">
          <div className="workcard-company-logo-container">
            <div
              className={`workcard-company-logo workcard-logo-${logo}`}
            ></div>
          </div>{' '}
          <div className="workcard-content">
            <div className="workcard-job-title">{jobTitle}</div>
            <div className="workcard-name">{name}</div>
            {context.isMobile && (
              <div className="workcard-name">
                {startDate} - {endDate}
              </div>
            )}
            <hr className="workcard-divider" />
            <div className="workcard-description">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCard;
