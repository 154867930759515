export const languages = [
  { name: 'HTML', devicon: 'html5' },
  { name: 'CSS', devicon: 'css3' },
  { name: 'JavaScript', devicon: 'javascript' },
  { name: 'Python', devicon: 'python' },
  { name: 'Java', devicon: 'java' },
  { name: 'Kotlin', devicon: 'kotlin' },
  { name: 'C++', devicon: 'cplusplus' },
  { name: 'C#', devicon: 'csharp' },
  { name: 'C', devicon: 'c' },
  { name: 'VHDL', path: '/icons/vhdl-skill.png' },
  { name: 'PHP', devicon: 'php' },
  { name: 'Bash', devicon: 'bash' },
  { name: 'Verilog', path: '/icons/verilog-skill.png' },
  { name: 'Go', devicon: 'go' },
  { name: 'Solidity', devicon: 'solidity' },
];

export const frontend = [
  { name: 'React', devicon: 'react' },
  { name: 'React Native', devicon: 'react' },
  { name: 'Flutter', devicon: 'flutter' },
  { name: 'Jest', path: '/icons/jest-skill.svg' },
  { name: 'Redux', devicon: 'redux' },
  { name: 'Bootstrap', devicon: 'bootstrap' },
  { name: 'D3', devicon: 'd3js' },
  { name: 'Material-UI', devicon: 'materialui' },
];

export const backend = [
  { name: 'Node.js', devicon: 'nodejs' },
  { name: 'Express.js', devicon: 'express' },
  { name: 'Flask', devicon: 'flask' },
  { name: 'Django', devicon: 'django' },
  { name: 'TensorFlow', path: '/icons/tensorflow-skill.svg' },
  { name: 'SQLAlchemy', devicon: 'sqlalchemy' },
  { name: 'MySQL', devicon: 'mysql' },
  { name: 'MongoDB', devicon: 'mongodb' },
  { name: 'Firebase', devicon: 'firebase' },
  { name: 'Cassandra', path: '/icons/cassandra-skill.svg' },
];

export const tools = [
  { name: 'Git', devicon: 'git' },
  { name: 'JIRA', path: '/icons/jira-skill.svg' },
  { name: 'Confluence', devicon: 'confluence' },
  { name: 'Postman', path: '/icons/postman-skill.svg' },
  { name: 'Docker', devicon: 'docker' },
  { name: 'PuTTY', path: '/icons/putty-skill.png' },
  { name: 'Wireshark', path: '/icons/wireshark-skill.png' },
  { name: 'UE4', path: '/icons/unrealengine4-skill.png' },
  { name: 'Unity 3D', path: '/icons/unity3d-skill.png' },
  { name: 'Arduino', path: '/icons/arduino-skill.svg' },
  { name: 'Socket.IO', devicon: 'socketio' },
  { name: 'Google Cloud', devicon: 'googlecloud' },
  { name: 'Kubernetes', devicon: 'kubernetes' },
  { name: 'ArgoCD', devicon: 'argocd' },
];

export const design = [
  { name: 'Photoshop', devicon: 'photoshop' },
  { name: 'Illustrator', devicon: 'illustrator' },
  { name: 'Figma', devicon: 'figma' },
];
